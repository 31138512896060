import React, { useEffect } from "react";

import { DesktopSidebar, DesktopSidebarNavItem, Header, Logo, SidebarGroup } from "@meterup/metric";
import { styled } from "@meterup/ui";
import { DrawerProvider } from "@meterup/ui/src/components/Drawer/DrawerProvider";
import { useIdentity } from "@meterup/ui/src/hooks/useIdentity";
import useRouter from "@meterup/ui/src/hooks/useRouter";
import { NavItem } from "@meterup/ui/src/Layouts/NavItem";
import {
  ChildrenWrapper,
  ContentWrapper,
  HeaderWrapper,
  Wrapper,
} from "@meterup/ui/src/styles/Layout/styles";
import { NavLink, useNavigate, useOutlet } from "react-router-dom";
import { Notifications } from "@meterup/ui/src/components/stolenFromFrontends/Notifications";

const SidebarWrapper = styled("div", {
  // width: "260px",
  maxWidth: 260,
  minWidth: 160,
});

export default function AdminLayout() {
  const children = useOutlet();
  const { handleOnClick } = useRouter();
  const navigate = useNavigate();
  useEffect(() => {
    if (children === null) {
      navigate("/dashboard/companies");
    }
  }, [children, navigate]);

  return (
    <Wrapper>
      <Notifications />
      <HeaderWrapper>
        <Header
          logo={
            <a href="/dashboard/companies" onClick={handleOnClick}>
              <Logo />
            </a>
          }
        />
      </HeaderWrapper>
      <ContentWrapper>
        <SidebarWrapper>
          <DesktopSidebar>
            <SidebarGroup>
              <NavLink to="/dashboard/companies" onClick={handleOnClick}>
                {({ isActive }) => (
                  <DesktopSidebarNavItem
                    icon="company"
                    label="Companies"
                    isSelected={isActive}
                    as={NavItem}
                  />
                )}
              </NavLink>
            </SidebarGroup>
          </DesktopSidebar>
        </SidebarWrapper>
        <ChildrenWrapper>
          <DrawerProvider>{children}</DrawerProvider>
        </ChildrenWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}
